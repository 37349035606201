<template>
    <div class="md:grid grid-cols-2 gap-2 border-t-2 border-red-600">
        <div class="m-8 text-left">
            © Copyright M27.PL 2021. All Rights Reserved.
        </div>
        <div class="flex m-6 text-left">
            <div class="m-2">
                <router-link to="/prywatnosc" class="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-small rounded-md text-red-600 hover:text-red-800 md:py-2  md:px-4">
                Polityka prywatności
                </router-link>
            </div>
            <div class="m-2">
                <router-link to="/regulamin" class="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-small rounded-md text-red-600 hover:text-red-800 md:py-2 md:px-4">
                Regulamin
                </router-link>                
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>