import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kurs',
    name: 'Kurs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Kurs.vue')
  },
  {
    path: '/zapisy',
    name: 'Zapisy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Zapisy.vue')
  },
  {
    path: '/prywatnosc',
    name: 'Prywatnosc',
    component: () => import(/* webpackChunkName: "about" */ '../views/Prywatnosc.vue')
  },
  {
    path: '/regulamin',
    name: 'Regulamin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Regulamin.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
