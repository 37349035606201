<template>
    <div v-if="!zgoda" class="fixed bottom-4 right-1 lg:right-4 p-4 bg-indigo-100 border-t-4 shadow-md">
	<h2 class="text-lg lg:text-xl mb-4 block font-bold leading-tight text-indigo-600">Ciasteczka</h2>
	<div class="lg:flex lg:space-x-5">
		<p class="mb-5 font-small text-gray-600">
			Ta strona korzysta z ciasteczek, aby świadczyć usługi na najwyższym poziomie. W związku z tym prosimy o Twoją dobrowolną zgodę na przetwarzanie Twoich danych osobowych. Wyrażając zgodę akceptujesz naszą
                <router-link to="/prywatnosc" class=" text-indigo-600 hover:text-indigo-800">
                Polityka prywatności
                </router-link>    
            i cookie i zgadzasz się na przetwarzanie Twoich danych osobowych.
		</p>
		<button
        @click="wchodze"
		class="w-full lg:w-48 px-3 py-1 bg-gray-100 hover:bg-white hover:text-red-700 rounded text-indigo-700 mr-2 mb-5 uppercase tracking-widest text-xs font-bold">
			Zgoda
		</button>
	</div>
</div>
</template>

<script>
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies).VueCookies.config('30d')

export default {
    name: 'Cookies',
    props: {
        msg: String
    },
    data() {
        return {
        zgoda: ""
        }
    },
    created() {
        if (this.$cookies.get("zgoda")) {
            this.zgoda = this.$cookies.get("zgoda")            
        } else {
           this.zgoda = false 
        }
    },
    methods: {
        wchodze: function () {
            this.zgoda = true;
            this.$cookies.set("zgoda", true)
        }
    },

}
</script>