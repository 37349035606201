<template>
<div class="md:grid grid-cols-2">
        <div class="lg:grid justify-items-end sm:text-center lg:text-right m-5">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span class="block ">KURSY </span>
            <span class="block text-red-600 xl:inline">YOUTUBE </span>
            <span class="block ">DLA DZIECI</span>
          </h1>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            12 wyjątkowych spotkań, dzięki którym twoje dziecko samodzielnie poprowadzi kanał na youtube!
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md ">
              <router-link to="/kurs"  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-500 md:py-4 md:text-lg md:px-10">
                dowiedz się więcej
              </router-link>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <a href="https://ef.m27.pl/kurs_yt" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-gray-300 hover:bg-gray-400 md:py-4 md:text-lg md:px-10">
                zapisy
              </a>
            </div>
          </div>
        </div>
        <div class="md:min-w-min m-10">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="/img/yt_twarz.png" alt="">
        </div>
</div>

</template>

<script>
// @ is an alias to /src
export default {
     data() {
    return {
      show: false
    }
}

}

</script>
