<template>
  <div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-2">
      <div class="relative z-10 pb-4 bg-white sm:pb-14 md:pb-16 lg:max-w-2xl lg:w-full lg:pb-22 xl:pb-24">
        <div class="relative pt-6 px-4 sm:px-6 lg:px-5">
          <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
            <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span class="sr-only">Workflow</span>
                  <img class="h-8 w-auto sm:h-10" src="/img/logo.png">
                </a>
                <div class="-mr-2 flex items-center md:hidden">
                  <button @click="show = !show" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" id="main-menu" aria-haspopup="true">
                    <span class="sr-only">Open main menu</span>
                    <!-- Heroicon name: menu -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
              <router-link to="/" class="font-medium text-gray-500 hover:text-gray-900">home</router-link>

              <router-link to="/kurs" class="font-medium text-gray-500 hover:text-gray-900">kurs</router-link>

              <a href="https://ef.m27.pl/kurs_yt" class="font-medium text-gray-500 hover:text-gray-900">zapisy</a>
            </div>
          </nav>
        </div>

        <!--
          Mobile menu, show/hide based on menu open state.

          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        -->
        <div v-if="show" class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="px-5 pt-4 flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="/img/logo.png" alt="">
              </div>
              <div class="-mr-2">
                <button @click="show = !show" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close main menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div @click="show = !show" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
              <div class="px-2 pt-2 pb-3 space-y-1" role="none">
                <router-link to="/" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">home</router-link>

                <router-link to="/kurs" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">kurs</router-link>

                <a href="https://ef.m27.pl/kurs_yt" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">zapisy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
    <Footer/>
        <Cookies/>
  </div>
</template>
<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Cookies from '@/components/Cookies.vue'

export default {
  name: 'Stopka',
  components: {
    Footer,
    Cookies
  },
    data() {
    return {
      show: false
    }
}
}
</script>
<style>
</style>
